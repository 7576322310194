import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

import {
    IContentBlockAdditionalContentItemViewProps,
    IContentBlockAdditionalContentViewProps
} from '@msdyn365-commerce-modules/content-block/src/modules/content-block/./components/additional-content';
import { IContentBlockViewProps } from '@msdyn365-commerce-modules/content-block/src/modules/content-block/./content-block';
//@ts-ignore
import { H2 } from '@churchofjesuschrist/eden-headings';
// import styled from 'styled-components';

/**
 * Render Additional Content.
 * @param additionalContent - Additional content view props.
 * @returns JSX Element.
 */

const renderAdditionalContent = (additionalContent: IContentBlockAdditionalContentViewProps) => {
    return (
        <Node {...additionalContent.additionalContentNode}>
            {additionalContent.additionalContentItems?.map((item: IContentBlockAdditionalContentItemViewProps) => {
                return (
                    <>
                        {item.heading}
                        <Node {...item.additionalContentItemContainer}>
                            {item.text}
                            <Node {...item.additionalContentItemLinks}>{item.links}</Node>
                        </Node>
                    </>
                );
            })}
        </Node>
    );
};

const ContentBlockView: React.FC<IContentBlockViewProps> = props => {
    const {
        contentBlockContainer,
        imageContainer,
        detailsContainer,
        title,
        text,
        links,
        image,
        // contentBlockAnchorTag,
        imageLink,
        imageAriaLabel,
        additionalContent
    } = props;
    const imageGradient =
        props.context.request.telemetryPageName === 'Homepage' || props.context.request.telemetryPageName === 'ChurchHomePage'
            ? 'linear-gradient(rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.6) 100%)'
            : '';
    // const ImageText = styled.div`
    //     background-image: ${imageGradient};
    //     cursor: pointer;
    //     @media (max-width: 600px) {
    //         background-image: none;
    //     }
    // `;
    const AdobeOnClick = (linkProps: any) => {
        if (
            (window && window['digitalData'] && props.context.request.telemetryPageName === 'Homepage') ||
            (window && window['digitalData'] && props.context.request.telemetryPageName === 'ChurchHomePage')
        ) {
            window['digitalData'].push({
                event: 'Page View',
                page: {
                    PageLink: {
                        title: linkProps.image.props.title,
                        text: linkProps.title.props.text,
                        link: linkProps.imageLink
                    }
                }
            });
        }

        window.location.assign(linkProps.imageLink);
    };

    if (imageLink) {
        return (
            <Module
                {...contentBlockContainer}
                style={{ marginBottom: props.context.request.telemetryPageName !== 'Homepage' ? '32px' : '' }}
            >
                <Node {...imageContainer} aria-label={imageAriaLabel}>
                    <div
                        style={{
                            position: 'relative',
                            overflow: 'hidden'
                        }}
                    >
                        <a
                            style={{
                                width:
                                    props.context.request.telemetryPageName === 'Homepage' ||
                                    props.context.request.telemetryPageName === 'ChurchHomePage'
                                        ? '100%'
                                        : '309px',
                                display: 'flex',
                                flexDirection: 'column',
                                cursor: 'pointer'
                            }}
                            onClick={() => AdobeOnClick(props)}
                        >
                            {/* {image} */}
                            {/* @ts-ignore */}
                            <img src={image.props.src} alt='' />
                        </a>
                        <div style={{ backgroundImage: `${imageGradient}`, cursor: 'pointer' }} className='image-text Image-Text'>
                            <h1 className='content-text'>
                                {/* @ts-ignore */}
                                {title.props.text}
                            </h1>
                        </div>
                    </div>
                </Node>
                <Node {...detailsContainer}>
                    {text}
                    {links}
                    {additionalContent && renderAdditionalContent(additionalContent)}
                </Node>
            </Module>
        );
    }
    return (
        <Module {...contentBlockContainer}>
            <Node {...imageContainer}>
                <div
                    style={{
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    {/* {image} */}
                    <div
                        style={{
                            width:
                                props.context.request.telemetryPageName === 'Homepage' ||
                                props.context.request.telemetryPageName === 'ChurchHomePage'
                                    ? '100%'
                                    : '309px',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'auto'
                        }}
                    >
                        {/* @ts-ignore */}
                        <img src={image.props.src} alt='' />
                    </div>
                    <div style={{ backgroundImage: `${imageGradient}`, cursor: 'pointer' }} className='image-text ImageText'>
                        <h1 className='content-text'>
                            {/* @ts-ignore */}
                            {title.props.text}
                        </h1>
                    </div>
                </div>
            </Node>
            <Node {...detailsContainer}>
                {/* {title} */}
                {text}
                {links}
                {additionalContent && renderAdditionalContent(additionalContent)}
            </Node>
        </Module>
    );
};
export default ContentBlockView;
